<template>
  <div class="page-box">
    <PageBannerBox>
      <FSImg :imgUrl="bannerInfo.imgUrl" />
      <BannerText
        :en="bannerInfo.en"
        :ch="bannerInfo.ch"
        :navList="navList"
        @changeActiveNav="changeActiveNav"
        :active="currentNav"
      />
      <SJXPoint v-if="!isMobile" />
    </PageBannerBox>
    <PageContent>
      <router-view />
    </PageContent>
  </div>
</template>

<script>
import { FSImg } from '@/components/Banner';
import BannerText from '../components/BannerText';
import PageBannerBox from '../components/Layout/PageBannerBox.vue';
import { SJXPoint } from '@/components/DownPoint';
import PageContent from '../components/Layout/PageContent.vue';
import { mapState } from 'vuex';
import { getBaseInfo } from '@/api/ShuJiuXiang/brand';
import { navList } from './dada';

export default {
  components: {
    FSImg,
    BannerText,
    PageBannerBox,
    SJXPoint,
    PageContent,
  },
  data() {
    return {
      navList,
      currentNav: '',
    };
  },
  mounted() {
    this.currentNav = this.$route.name;
    this.getBaseInfo();
  },
  methods: {
    changeActiveNav(key, url) {
      this.$router.push(url);
      this.currentNav = key;
    },
    // 基本信息
    async getBaseInfo() {
      const res = await getBaseInfo();
      this.$store.commit('sjx/brand/BASE_INFO', res);
    },
  },
  computed: {
    ...mapState({
      isMobile: 'isMobile',
      isNearMobile: 'isNearMobile',
      baseInfo: (state) => state.sjx.brand.baseInfo,
    }),
    bannerInfo() {
      const { name } = this.$route;
      let en = '';
      let ch = '';
      let imgUrl = '';
      switch (name) {
        case 'sjxBrandSpecial':
          ch = this.baseInfo.foodChinese;
          en = this.baseInfo.foodEnglish;
          imgUrl = this.baseInfo.foodImage;
          break;
        case 'sjxBrandEnv':
          ch = this.baseInfo.storeChinese;
          en = this.baseInfo.storeEnglish;
          imgUrl = this.baseInfo.storeImage;
          break;
        case 'sjxBrandShop':
          ch = this.baseInfo.nationalStoresChinese;
          en = this.baseInfo.nationalStoresEnglish;
          imgUrl = this.baseInfo.nationalStoresImage;
          break;
        default:
          ch = this.baseInfo.storyChinese;
          en = this.baseInfo.storyEnglish;
          imgUrl = this.baseInfo.storyImage;
          break;
      }
      return { en, ch, imgUrl };
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/ShuJiuXiang/theme.scss';
.page-box {
  ::v-deep .pageContent {
    padding: 0;
  }
  @media screen and (max-width: $res-point-6) {
    padding-top: 60px;
  }
}
</style>
